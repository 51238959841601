import React, { useState, useEffect, Suspense, lazy } from 'react';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';
import { usersInstance } from './axios-settings';
import { GlobalStyle } from '../src/components/styles/globalStyles';
import AuthContext from './context/authContext';
import userMeContext from './context/userMeContext';

const Auth = lazy(() => import('./components/Auth'));
const RemindPass = lazy(() => import('./components/RemindPass'));
const Register = lazy(() => import('./components/RegisterPass'));
const Layout = lazy(() => import('./components/Layout'));
const AccountSettings = lazy(() => import('./components/AccountSettings/AccountSettings'));
const UserView = lazy(() => import('./components/UserView'));

const AppWrapper = styled.div``;

const app = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [incorrectEmailOrPassword, setIncorrectEmailOrPassword] = useState(false);
    const [userMe, setUserData] = useState({})

    useEffect(() => {
        isLoggedIn && usersInstance.get('me')
            .then(response => {
                setUserData(response.data)
            })
            .catch(err => console.log(err))

        if (window.localStorage.getItem('token')) {
            setIsLoggedIn(true);
        }
    }, [isLoggedIn]);

    const login = (values) => {
        usersInstance.post('token', values)
            .then(res => {
                window.localStorage.setItem('token', res.data.token);
                window.localStorage.setItem('refreshToken', res.data.refresh_token);
                window.localStorage.setItem('expiresAt', res.data.expires_at);
                setIsLoggedIn(true);
            })
            .catch(err => {
                console.log('ERR', err);
                setIncorrectEmailOrPassword(true);
                console.log(err.response);
            });
    }

    const logout = () => {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('refreshToken');
        window.localStorage.removeItem('expiresAt');
        setIsLoggedIn(false);
    }

    return (
        <AppWrapper>
            <GlobalStyle />
            <Suspense fallback={<h1>Loading view...</h1>}>
                <userMeContext.Provider value={{ userMe }}>
                    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
                        {!isLoggedIn
                            ? <Switch>
                                <Route path="/register/:registerToken" render={() => <Register />} />
                                <Route path="/password-remind" render={() => <RemindPass />} />
                                <Route path="/users/password" render={() => <Register resetingForm={true} />} />

                                <Route path="/" render={() => <Auth loginError={incorrectEmailOrPassword} />} />

                            </Switch>
                            : <Switch>
                                <Route path="/account-settings" render={() => <AccountSettings user={userMe} />} />
                                <Route path="/admin" render={() => <Layout user={userMe} />} />
                                <Route path="/" render={() => <UserView user={userMe} />} />
                            </Switch>
                        }
                    </AuthContext.Provider>
                </userMeContext.Provider>
            </Suspense>
        </AppWrapper >
    );
}

export default app;