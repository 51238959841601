import axios from 'axios';

const instance = axios.create({
  simpleURL: process.env.REACT_APP_API_URL,
  baseURL: process.env.REACT_APP_API_URL + 'api/',
  headers: { 'Authorization': 'Bearer ' + window.localStorage.getItem('token') }
});

export const usersInstance = axios.create({
  simpleURL: process.env.REACT_APP_USERS_URL,
  baseURL: process.env.REACT_APP_USERS_URL + 'api/',
  headers: { 'Authorization': 'Bearer ' + window.localStorage.getItem('token') }
});

export const logsInstance = axios.create({
  // simpleURL: process.env.REACT_APP_API_URL,
  baseURL: process.env.REACT_APP_LOGS_URL + 'api/',
  headers: { 'Authorization': 'Bearer ' + window.localStorage.getItem('token') }
});


let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })

  failedQueue = [];
}


const config = config => {
  config.headers['Authorization'] = 'Bearer ' + window.localStorage.getItem('token');
  return config;
}

instance.interceptors.request.use(config);
usersInstance.interceptors.request.use(config);
logsInstance.interceptors.request.use(config);
// const error =


let error = error => {
  console.log('ERROR', error);
  const originalRequest = error.config;

  if ((error.response.status === 403) && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject })
      }).then(token => {
        originalRequest.headers['Authorization'] = 'Bearer ' + token;
        return instance(originalRequest);
      }).catch(err => err)
    }

    originalRequest._retry = true;
    isRefreshing = true;

    const refreshToken = window.localStorage.getItem('refreshToken');
    return new Promise((resolve, reject) => {
      usersInstance.post('token/refresh', { refresh_token: refreshToken })
        .then(({ data }) => {

          window.localStorage.setItem('token', data.token);
          window.localStorage.setItem('refreshToken', data.refresh_token);
          window.localStorage.setItem('expiresAt', data.expires_at);
          instance.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
          usersInstance.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
          logsInstance.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
          originalRequest.headers['Authorization'] = 'Bearer ' + data.token;
          processQueue(null, data.token);
          resolve(instance(originalRequest));
        })
        .catch(err => {
          processQueue(err, null);
          reject(err);
        })
        .then(() => isRefreshing = false)
    })
  }
  return Promise.reject(error);
}


instance.interceptors.response.use(response => {
  return response;
}, error);

usersInstance.interceptors.response.use(response => {
  return response;
}, error);

logsInstance.interceptors.response.use(response => {
  return response;
}, error);

export default instance;